import React, { useEffect } from 'react';
import { Route, useRouteMatch } from 'react-router';
import {
  Header,
  Banner,
  Search,
  Footer,
  PriceSwitchProvider,
  CruiseList,
  CruiseDetails,
  ErrorBoundary,
} from './components';
import {
  CRUISES_ROUTE,
  BaseDataProvider,
  UserProvider,
  SearchParameterProvider,
  GlobalStyle,
} from './lib';
import { trackGA4PageView, TITLE, trackPageView } from './tealium';

export default function App() {
  trackPageView({
    title: window.location.href.indexOf('/cruises') >= 0
      ? `${TITLE} - Suchergebnisse`
      : TITLE,
  });

  const matchDetails = useRouteMatch<{ detail: string }>(`${CRUISES_ROUTE}/:detail`);
  const matchCruises = useRouteMatch(CRUISES_ROUTE);

  useEffect(() => {
    if (matchDetails) {
      //gets called directly after the call
    } else if (matchCruises) {
      trackGA4PageView('expertsearch_meinschiff_com|suchergebnisseite');
    } else {
      trackGA4PageView('expertsearch_meinschiff_com|startseite');
    }
  }, [matchCruises, matchDetails]);

  return (
    <>
      <GlobalStyle />
      <Header />
      <ErrorBoundary>
        <UserProvider>
          <BaseDataProvider>
            <PriceSwitchProvider>
              <SearchParameterProvider>
                <Banner>
                  <Search />
                </Banner>
                <Route path={CRUISES_ROUTE} component={CruiseList} />
                <CruiseDetails />
                <Footer />
              </SearchParameterProvider>
            </PriceSwitchProvider>
          </BaseDataProvider>
        </UserProvider>
      </ErrorBoundary>
    </>
  );
}
