import { format } from '../../../lib';
import { postCruiseList } from '../../../api';
import { BaseData } from '../../../lib';
import convertCruises from '../convertCruises';
import { useSearchStore } from '../../../state/search';

const getCruises = async (baseData: BaseData) => {

  const { search } = useSearchStore.getState();

  const request = {
    paging: { resultsTotal: 999999 },
    composition: 'JourneyIdentifierPriceCategoryCabinType',
    passenger: {
      adults: 2,
      children: 0,
    },
    period: {
      start: {
        date: format((search && search.firstCheckInDate ? search.firstCheckInDate : new Date()), 'yyyy-MM-dd'),
        postOffset: 0,
        preOffset: 0,
      }, end: {
        date: format((search && search.lastCheckOutDate ? search.lastCheckOutDate : new Date()), 'yyyy-MM-dd'),
        postOffset: 0,
        preOffset: 0,
      },
    },
  };

  const controller = new AbortController();
  const signal = {
    signal: controller.signal,
  };

  return convertCruises(
    await postCruiseList(request, signal),
    baseData,
  );
};

export default getCruises;
