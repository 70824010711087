import * as React from 'react';
import { isAfter, isBefore, parseISO } from 'date-fns/esm';
import memoizeOne from 'memoize-one';

import { isOption, Option } from '../types';

import { BaseDataResponse, cabinTypeEnum, getBaseData } from '../api';
import { captureMessage, inRange, isAbort, Severity, withSentryData } from '../lib';
import useAsyncEffect from './useAsyncEffect';
import { withAbort } from './fetch';
import { formatISO } from './formatDate';

const IGNORE_PATHS = ['processInfo.processMessages', 'cruise.ports'];

export type BaseData = BaseDataResponse;

function removePartialOptions(data: Object[], key: string): Object[] {
  return data.filter((data: any, i: number, self): data is Option => {
    const result = isOption(data);

    if (data.name === '----- ' && i !== 0) {
      (self[i - 1] as Option).separate = true;
    } else if (!result) {
      captureMessage(
        withSentryData({ severity: Severity.Warning })(
          `${key}[${i}] is not a valid option`,
        ),
      );
    }

    return result;
  });
}

type DataObject = {
  [key: string]:
    | DataObject
    | string
    | string[]
    | Object[]
    | boolean
    | number
    | undefined;
};

function getVal(value: DataObject[string], path: string[]) {
  if (IGNORE_PATHS.includes(path.join('.'))) {
    return value;
  }

  if (Array.isArray(value)) {
    if (value.length >= 1 && typeof value[0] === 'object') {
      return removePartialOptions(value, path.join('.'));
    }
  } else if (typeof value === 'object') {
    return removePartialOptionsRecursive(value, path);
  }

  return value;
}

function removePartialOptionsRecursive<D extends DataObject>(
  data: D,
  parentKeys: string[] = [],
): D {
  return Object.entries(data).reduce(
    (memo, [key, value]: [keyof D, DataObject[string]]) => {
      const path = parentKeys.concat(`${key}`);

      memo[key] = getVal(value, path) as any;

      return memo;
    },
    {} as D,
  );
}

function movePastDatesToPresent({
                                  periods: { firstCheckInDate, lastCheckOutDate },
                                  ...rest
                                }: BaseDataResponse): BaseDataResponse {
  const now = new Date();
  now.setDate(now.getDate() + 3);
  return {
    ...rest,
    periods: {
      firstCheckInDate: isBefore(parseISO(firstCheckInDate), now)
        ? formatISO(now)
        : firstCheckInDate,
      lastCheckOutDate: isBefore(parseISO(lastCheckOutDate), now)
        ? formatISO(now)
        : lastCheckOutDate,
    },
  };
}

function sortSpecialShips({
                            ...rest
                          }: BaseDataResponse): BaseDataResponse {
  return {
    ...rest,
    cruise: {
      ...rest.cruise,
      shipInfos: rest.cruise.shipInfos.map((value, index) => ({ value, index })) // Attach original indices
        .sort((a, b) => {

          // Mein Schiff Flow soll nach Relax auftauchen
          if (a.value.code === 'MEINSF' && b.value.code === 'MEINSR') {
            return 1;
          }

          return a.value.code.localeCompare(b.value.code);
        })
        .map(item => item.value),
    },
  };
}

export const createNormalizers = memoizeOne(
  ({
     cruise: {
       duration,
       passengers: { minAdults, maxAdults, minChildren, maxChildren },
       shipInfos,
       cabinTypeInfos,
     },
     flight: { departureAirportInfos: departureAirports },
     geoLocations: { regionInfos },
     periods: { firstCheckInDate, lastCheckOutDate },
   }: BaseData) => {
    const durationCodes = duration.map(({ code }) => code);
    const regionCodes = regionInfos.map(({ code }) => code);
    const airportCodes = departureAirports.map(({ code }) => code);
    const shipCodes = shipInfos.map(({ code }) => code);
    const cabinCodes = cabinTypeInfos.map(({ code }) => code);

    return {
      adults: (amount: number) => inRange(amount, [minAdults, maxAdults]),
      children: (amount: number) => inRange(amount, [minChildren, maxChildren]),
      startDate: (date: string) =>
        isBefore(parseISO(firstCheckInDate), parseISO(date))
          ? date
          : firstCheckInDate,
      endDate: (date: string) =>
        isAfter(parseISO(lastCheckOutDate), parseISO(date))
          ? date
          : lastCheckOutDate,
      duration: (duration: string) =>
        duration.length && durationCodes.includes(duration)
          ? duration
          : undefined,
      regions: (regions: string[]) =>
        regions.filter((code) => regionCodes.includes(code)),
      airports: (airports: string[]) =>
        airports.filter((code) => airportCodes.includes(code)),
      ships: (ships: string[]) =>
        ships.filter((code) => shipCodes.includes(code)),
      cabins: (cabins: string[]): cabinTypeEnum[] =>
        cabins.filter((code): code is cabinTypeEnum =>
          cabinCodes.includes(code),
        ),
    };
  },
);

function normalizeBaseData(data: BaseDataResponse): BaseDataResponse {
  return sortSpecialShips(movePastDatesToPresent(removePartialOptionsRecursive(data)));
}

const BaseDataContext = React.createContext<BaseData | null | Error>(null);

export const BaseDataProvider: React.FC = ({ children }) => {
  const [baseData, setBaseData] = React.useState<BaseData | null | Error>(null);
  useAsyncEffect(async (onUnmount) => {
    try {
      const data = await getBaseData(withAbort(onUnmount));

      setBaseData(normalizeBaseData(data));
    } catch (err) {
      if (isAbort(err)) {
        return;
      }

      if (err instanceof Error) {
        setBaseData(err);
      }
      throw err;
    }
  }, []);

  return (
    <BaseDataContext.Provider value={baseData}>
      {children}
    </BaseDataContext.Provider>
  );
};

export default function useBaseData(): BaseData;
export default function useBaseData(force: true): BaseData | null | Error;
export default function useBaseData(force?: true): BaseData | null | Error {
  const baseData = React.useContext(BaseDataContext);

  if (force === true) {
    return baseData;
  }

  if (baseData instanceof Error || !baseData) {
    throw new Error('Could not find BaseData');
  }

  return baseData;
}
