import * as React from 'react';
import styled from 'styled-components/macro';

import { useActive, colors } from '../lib';
import { trackEvent, trackGA4FormInteraction } from '../tealium';

type PriceSwitchContextType = {
  setPerPerson: (perPerson: boolean) => void;
  perPerson: boolean;
};
const PriceSwitchContext = React.createContext<PriceSwitchContextType | null>(
  null,
);

export const PriceSwitchProvider: React.FC = ({ children }) => {
  const [perPerson, setPerPerson] = React.useState<boolean>(true);
  const value = React.useMemo(() => ({ perPerson, setPerPerson }), [
    perPerson,
    setPerPerson,
  ]);
  return (
    <PriceSwitchContext.Provider value={value}>
      {children}
    </PriceSwitchContext.Provider>
  );
};

export function usePerPerson() {
  const perPerson = React.useContext(PriceSwitchContext);

  if (!perPerson) {
    throw new Error('Missing <PriceSwitchProvider /> in parent Components');
  }

  return perPerson;
}

const formatter = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 2,
});

export function formatPrice(price: number) {
  return formatter.format(price).replace(/,00/, ',-');
}

type Price = {
  amount: number;
};
type PriceFormatterParams = { amount: number; perPerson: Price[] };

export function usePriceFormat(): (
  { amount, perPerson }: PriceFormatterParams,
  diffTo?: PriceFormatterParams,
) => string {
  const { perPerson: displayPerPerson } = usePerPerson();
  return React.useCallback(
    ({ amount, perPerson }, diffTo) => {
      const basePrice = displayPerPerson ? perPerson[0].amount : amount;

      const diff = diffTo
        ? displayPerPerson
          ? diffTo.perPerson[0].amount
          : diffTo.amount
        : 0;
      return `${diffTo ? (basePrice < diff ? '- ' : '+ ') : ''}${formatPrice(
        Math.abs(basePrice - diff),
      )}`;
    },
    [displayPerPerson],
  );
}

const PhantomCheckbox = styled.input`
  opacity: 0;
  width: 0;
`;

const Wrapper = styled.label`
  margin-top: 14px;
  user-select: none;
  display: inline-block;
  height: 32px;
`;

type TextProps = { active: boolean };
const Text = styled.span`
  display: block;
  height: 32px;
  float: left;
  line-height: 32px;
  color: ${({ active }: TextProps) => (active ? colors.blue : colors.grayBlue)};
  cursor: ${({ active }: TextProps) => (active ? 'default' : 'pointer')};
`;

type SwitchProps = {
  right?: boolean;
  active?: boolean;
};
const switchShadow = '0 2px 6px 0 rgba(46, 69, 91, 0.2)';
const Switch = styled.span`
  position: relative;
  display: block;
  width: 64px;
  float: left;
  height: 32px;
  margin: 0 16px 0 15px;
  border-radius: 24px;
  background-color: ${colors.lightGrayBlue};
  box-shadow: ${({ active }: SwitchProps) => (active ? switchShadow : 'none')};
  cursor: pointer;

  &::after {
    content: '';
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background-color: ${colors.darkBlue};
    position: absolute;
    display: block;
    top: 4px;
    transition: left 100ms;
    left: ${({ right }: SwitchProps) => (right ? '36px' : '4px')};
  }

  &:focus,
  &:hover {
    box-shadow: ${switchShadow};
  }
`;

const PriceSwitch: React.FC = () => {
  const [ref, active] = useActive<HTMLLabelElement>({ mouse: false });
  const { setPerPerson, perPerson } = usePerPerson();

  const switchPerPerson = (perPersonState: boolean) => {
    setPerPerson(perPersonState);

    trackEvent({
      action: 'Suchergebnisse_Preisansicht',
      label: perPersonState ? 'Preis pro Person' : 'Gesamtpreis',
    });

    trackGA4FormInteraction('kreuzfahrtsuche', 'filled.price-per-person.' + (perPersonState ? 'true' : 'false'));
  };

  return (
    <Wrapper ref={ref}>
      <Text
        active={perPerson}
        onClick={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          switchPerPerson(true);
        }}
      >
        Preise pro Person
      </Text>
      <Switch right={!perPerson} active={active} />
      <Text
        active={!perPerson}
        onClick={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          switchPerPerson(false);
        }}
      >
        Gesamtpreis
      </Text>
      <PhantomCheckbox
        type="checkbox"
        onChange={() => switchPerPerson(!perPerson)}
      />
    </Wrapper>
  );
};

export default PriceSwitch;
