import * as React from 'react';
import styled from 'styled-components/macro';

import { Cruise as CruiseType, Cabin } from './Types';
import { Td, Title, TitleSecondTd } from '../Table';
import CheapestCabin from './CheapestCabin';
import EarlyBirdHint from './EarlyBirdHint';
import CruiseRow from './CruiseRow';
import CabinRow from './CabinRow';

import { ArrowUp, useTariffs, format } from '../../lib';
import { trackEvent, trackGA4ClickEvent } from '../../tealium';
import { isFilteredOption, Option } from '../../types';

const CruiseDateWrap = styled.span`
  width: 120px;
  margin-right: 24px;
  display: block;
  float: left;
`;
const CloseLink = styled(ArrowUp)`
  position: absolute;
  left: -50px;
  top: 50%;
  margin-top: -8px;
`;

type Props = {
  cruise: CruiseType;
  filteredTariffs?: Option[] | [];
};

const CruiseTitleTd = styled(Td)`
  height: 118px;
  overflow: hidden;
`;

const Cruise: React.FC<Props> = React.memo(({ cruise, filteredTariffs }) => {
  const tariffs = useTariffs();
  const [open, setOpen] = React.useState<boolean>(false);
  const toggleOpen = React.useCallback(
    (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      ev.preventDefault();
      setOpen(!open);

      if (!open) {
        trackEvent({
          action: 'Suchergebnisse_Details',
          label: cruise.name,
        });
        trackGA4ClickEvent('content.list-entry.' + cruise.name);
      }

    },
    [open, setOpen, cruise],
  );

  const cabinsByName = React.useMemo(
    () =>
      Object.values(
        cruise.cabins.reduce(
          (memo, cabin) => {
            if (!memo[cabin.name]) {
              memo[cabin.name] = [];
            }

            memo[cabin.name].push(cabin);

            return memo;
          },
          {} as { [key: string]: Cabin[] },
        ),
      ),
    [cruise.cabins],
  );

  const {
    name,
    startDate,
    journeyIdentifier,
    nights,
    shipName,
    cabins,
  } = cruise;
  const date = format(startDate);
  return (
    <>
      <CruiseRow onClick={toggleOpen} open={open}>
        <CruiseTitleTd>
          <CruiseDateWrap>
            <Title>{date}</Title>
            {nights} {nights === '1' ? 'Nacht' : 'Nächte'}
          </CruiseDateWrap>
          <TitleSecondTd>
            <Title>{name}</Title>
            {shipName}
          </TitleSecondTd>
        </CruiseTitleTd>
        {tariffs.map((tariff) =>
          open ? (
            <EarlyBirdHint cabins={cabins} tariff={tariff} key={tariff.code} />
          ) : (
            <CheapestCabin
              cabins={cabins}
              tariff={tariff}
              key={tariff.code}
              filtered={isFilteredOption(tariff.code, filteredTariffs)}
            />
          ),
        )}
        {open ? <CloseLink /> : null}
      </CruiseRow>
      {open
        ? cabinsByName.map((cabins) => (
          <CabinRow
            startDate={startDate}
            journeyIdentifier={journeyIdentifier}
            key={cabins[0].name}
            cabins={cabins}
          />
        ))
        : null}
    </>
  );
});

export default Cruise;
