import * as React from 'react';

import {
  CRUISES_ROUTE,
  formatISO,
  isAbort,
  parseDetailParams,
  useAsyncEffect,
  useBaseData,
  withAbort,
  withSentryData,
} from '../../lib';
import { trackEvent, trackGA4PageView } from '../../tealium';
import { convertCruises } from '../CruiseList';
import { postCruiseList } from '../../api';
import ErrorWithReset from '../ErrorWithReset';
import { ModalBox } from '../Modal';
import Spinner from '../Spinner';

import CruiseDetails from './CruiseDetails';
import { useCruiseStore } from '../../state/cruise';

type Props = {
  params: string;
};

const SOME_ERROR = Symbol('SOME_ERROR');

const CruiseDetailsProvider: React.FC<Props> = ({ params: rawParams }) => {
  const baseData = useBaseData();
  const { cruise, setCruise } = useCruiseStore();
  const params = React.useMemo(() => parseDetailParams(rawParams), [rawParams]);
  const detailRequest = React.useMemo(
    () => ({
      passenger: {
        adults: params.adults,
        children: params.children,
      },
      period: {
        start: {
          preOffset: 0,
          date: formatISO(params.startDate),
          postOffset: 0,
        },
      },
      cruise: {
        journeyIdentifier: [params.journeyIdentifier],
        cabinCodes: [params.cabinCode],
        cabinTypes: [params.cabinType],
        tariffTypes: [params.tariffCode],
      },
      flight: {
        allowOpenJaw: true,
      },
      composition: 'None',
    }),
    [params],
  );
  useAsyncEffect(
    async (onUnmount) => {
      setCruise(null);

      try {
        const detailCruises = convertCruises(
          await postCruiseList(detailRequest, withAbort(onUnmount)),
          baseData,
        );
        if (detailCruises.length !== 1) {
          throw withSentryData({ extras: detailRequest })(
            new Error(
              `Unexpectedly got ${detailCruises.length} cruises in detail cruiseList request`,
            ),
          );
        }

        trackGA4PageView('expertsearch_meinschiff_com|detail|', detailCruises[0].name);
        setCruise(detailCruises[0]);
      } catch (err) {
        /* not really testable due to lacking visible impact for the user */
        /* istanbul ignore if */
        if (isAbort(err)) {
          return;
        }

        setCruise(SOME_ERROR);
        throw err;
      }
    },
    [detailRequest],
  );


  return (
    <>
      {cruise === null ? <Spinner>Loading...</Spinner> : null}
      <ModalBox active={Boolean(cruise)} close={CRUISES_ROUTE} onClose={() => {
        setCruise(null);
        trackEvent({
          action: 'Reiseauswahl_schließen',
          label: 'Button_X',
        });
      }}>
        {cruise === SOME_ERROR ? (
          <ErrorWithReset />
        ) : cruise !== null ? (
          <CruiseDetails
            cruise={cruise}
            params={params}
            request={detailRequest}
          />
        ) : null}
      </ModalBox>
    </>
  );
};

export default CruiseDetailsProvider;
