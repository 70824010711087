import * as React from 'react';
import { parseISO, min, addDays, addMonths } from 'date-fns';

import { useBaseData, useSearchParameters, createNormalizers } from '../../lib';
import { cabinTypeEnum } from '../../api';
import { Option } from '../../types/Option';

import Search from './Search';

const INITIAL_ADULTS = 2;
const INITIAL_CHILDREN = 0;
const INITIAL_MONTH_RANGE = 3;
const NO_DURATION: Option = {
  code: '',
  name: 'Nicht festgelegt',
  separate: true,
};

function useResetState(searchState: null | any) {
  const baseData = useBaseData();
  return React.useMemo(() => {
    const normalize = createNormalizers(baseData);
    const {
      cruise: {
        duration,
        passengers,
        dateRange = Infinity,
        shipInfos: ships,
        cabinTypeInfos: cabinTypes,
      },
      flight: {departureAirportInfos: departureAirports},
      geoLocations: {regionInfos: regions},
      periods,
    } = baseData;
    const firstCheckInDate = searchState
      ? parseISO(searchState.period.start.date)
      : parseISO(periods.firstCheckInDate);
    const baseDataLastCheckoutDate = parseISO(periods.lastCheckOutDate);
    const lastCheckOutDate = searchState
      ? parseISO(searchState.period.end.date)
      : min([
        addMonths(firstCheckInDate, INITIAL_MONTH_RANGE),
        baseDataLastCheckoutDate,
      ]);

    return {
      initialState: {
        arrivalLikeDeparture: false,
        adults: searchState
          ? searchState.passenger.adults
          : normalize.adults(INITIAL_ADULTS),
        children: searchState
          ? searchState.passenger.children
          : normalize.children(INITIAL_CHILDREN),
        cruiseDuration:
          (searchState &&
            duration.find(
              ({code}) => code === searchState.period.duration,
            )) ||
          NO_DURATION,
        arrivalAirports:
          (searchState &&
            departureAirports.filter(({code}) =>
              searchState.flight.inbound.arrivalAirports.includes(code),
            )) ||
          [],
        ships:
          (searchState &&
            ships.filter(({code}) =>
              searchState.cruise.shipCodes.includes(code),
            )) ||
          [],
        regions:
          (searchState &&
            regions.filter(({code}) =>
              searchState.geoLocation.regions.includes(code),
            )) ||
          [],
        cabinTypes:
          (searchState &&
            cabinTypes.filter(({code}) =>
              searchState.cruise.cabinTypes.includes(code as cabinTypeEnum),
            )) ||
          [],
        departureAirports:
          (searchState &&
            departureAirports.filter(({code}) =>
              searchState.flight.outbound.departureAirports.includes(code),
            )) ||
          [],
        firstCheckInDate,
        lastCheckOutDate:
          dateRange === Infinity
            ? lastCheckOutDate
            : min([addDays(firstCheckInDate, dateRange), lastCheckOutDate]),
      },
      options: {
        cruise: {
          duration: [NO_DURATION].concat(duration),
          passengers,
          dateRange,
          ships,
          cabinTypes,
        },
        flight: {
          departureAirports,
        },
        periods: {
          firstCheckInDate: parseISO(periods.firstCheckInDate),
          lastCheckOutDate: parseISO(periods.lastCheckOutDate),
        },
        geoLocations: {
          regions,
        },
      },
    };
  }, [baseData, searchState]);
}

export function useSearchData() {
  const searchState = useSearchParameters();
  return useResetState(searchState);
}

const SearchDataProvider: React.FC = ({ children }) => {
  const searchData = useSearchData();
  const {initialState: resetState } = useResetState(null);

  return <Search resetState={resetState} {...searchData} >{children}</Search>;
};

export default SearchDataProvider;
